import classNames from 'classnames';
import { FlipLabel } from '@/shared/components';
import { Typography } from '@/shared/components/atoms/Typography';
import useAuction from '@/shared/hooks/useAuction';
import AuctionIcon from '@/shared/icons/Auction';
import Distance from '@/shared/icons/Distance';
import type { TokenAmount } from '@/shared/utils';
import FlipAmount from '@/shared/utils/FlipAmount';
import { DiffLabel } from './DiffLabel';

export interface BidPreviewProps {
  totalBalance: TokenAmount;
  className?: string;
}

export function BidPreview({ totalBalance, className }: BidPreviewProps): JSX.Element {
  const { auction, isLoading: isAuctionsLoading } = useAuction();

  const minActiveBid = auction?.minActiveBid ?? FlipAmount.ZERO;
  const distanceFromMAB = totalBalance.sub(minActiveBid);
  const distanceFromMABPercentage = totalBalance.difference(minActiveBid);

  return (
    <div
      className={classNames(
        'relative h-full w-full overflow-hidden rounded-xl bg-cf-gray-2 px-5 py-6',
        className,
      )}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col justify-between space-y-2 sm:flex-row sm:space-x-4">
          <div className="flex items-center space-x-2">
            <AuctionIcon className="stroke-current" />
            <Typography variant="label">Min. Active Bid</Typography>
          </div>
          <div>
            <FlipLabel large amount={minActiveBid} prefix="~" isLoading={isAuctionsLoading} />
          </div>
        </div>
        <div className="flex flex-col justify-between space-y-2 sm:flex-row sm:space-x-4">
          <div className="flex items-center space-x-2">
            <Distance className="stroke-current" />
            <div className="flex flex-col sm:flex-row sm:space-x-2">
              <Typography variant="label">New difference from MAB</Typography>
              {!minActiveBid.value.isEqualTo(0) && (
                <DiffLabel percentage={distanceFromMABPercentage} className="text-12 sm:text-14" />
              )}
            </div>
          </div>
          <div>
            {minActiveBid.value.isEqualTo(0) ? (
              <span className="text-cf-white">-</span>
            ) : (
              <FlipLabel
                large
                amount={distanceFromMAB.abs()}
                prefix="~"
                isLoading={isAuctionsLoading}
                className={distanceFromMAB.gt(0) ? 'text-cf-green-1' : 'text-cf-red-1'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
