import classNames from 'classnames';
import ArbitrumLogo from '@/shared/assets/svg/chainpill-logos/arb.svg';
import BitcoinLogo from '@/shared/assets/svg/chainpill-logos/btc.svg';
import PolkadotLogo from '@/shared/assets/svg/chainpill-logos/dot.svg';
import EthereumLogo from '@/shared/assets/svg/chainpill-logos/eth.svg';
import SolanaLogo from '@/shared/assets/svg/chainpill-logos/sol.svg';
import { type ChainflipChain } from '../../utils/chainflip';

const monoChromeLogo: Record<ChainflipChain, JSX.Element> = {
  Bitcoin: <BitcoinLogo />,
  Ethereum: <EthereumLogo />,
  Polkadot: <PolkadotLogo />,
  Arbitrum: <ArbitrumLogo />,
  Solana: <SolanaLogo />,
};

// https://www.figma.com/file/6DpozwFwDYXTu6HFsbijhZ/Flip-UI-Kit?type=design&node-id=1953-2258&mode=dev
const chainColors: Record<ChainflipChain, string> = {
  Bitcoin: 'bg-[#423225] text-[#F7931B] border border-[#F7931B]/20',
  Ethereum: 'bg-[#252545] text-[#8198EE] border border-[#8198EE]/20',
  Polkadot: 'bg-[#422535] text-[#EA3E99] border border-[#EA3E99]/20',
  Arbitrum: 'bg-[#213147] text-[#12AAFF] border border-[#3D628C]/20',
  Solana: 'bg-[#31183D] text-[#8974DD] border border-[#462A5A]',
};

const ChainPill = ({ chain }: { chain: ChainflipChain }) => (
  <div
    className={classNames(
      'inline-flex items-center gap-x-0.5 rounded-full px-2 py-0.5 text-12',
      chainColors[chain],
    )}
  >
    <div>{monoChromeLogo[chain]}</div>
    {chain}
  </div>
);

export default ChainPill;
